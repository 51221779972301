import { permissionsList } from '@/utils/permissions'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@views/0_Everyone/Home.vue'),
      meta: {
        breadcrumb: [
          { 
            name: "Home",
            link: "/"
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@views/auth/Login.vue'),
    },
    {
      path: '/login-callback',
      name: 'LoginCallback',
      component: () => import('@views/auth/LoginCallback.vue'),
    },
    {
      path: '/settings-callback',
      name: 'SettingsCallback',
      component: () => import('@views/auth/SettingsCallback.vue'),
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('@views/auth/Logout.vue'),
    },

    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import('@views/auth/ForgotPassword.vue'),
    },

    // Everyone
    {
      path: '/panel',
      name: 'Dashboard',
      component: () => import('@views/0_Everyone/Dashboard.vue'),
      meta: {
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
        ]
      }
    },

    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@views/0_Everyone/Settings.vue'),
      meta: {
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Einstellungen",
            link: "/settings"
          },
        ]
      }
    },

    {
      path: '/patrols',
      name: 'Patrols',
      component: () => import('@views/0_Everyone/PatrolOverview.vue'),
      meta: {
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Streifen",
            link: "/patrols"
          },
        ]
      }
    },

    {
      path: '/members',
      name: 'Members',
      component: () => import('@views/0_Everyone/Members.vue'),
      meta: {
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Mitglieder",
            link: "/members"
          },
        ]
      }
    },

    // Admin Global Settings
    {
      path: '/admin/global-settings',
      name: 'AdminGlobalSettings',
      component: () => import('@views/2_Admin/global-settings.vue'),
      meta: {
        permission: permissionsList.ADMIN_GLOBAL_SETTINGS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Einstellungen",
            link: "/admin/global-settings"
          },
        ]
      }
    },

    // Admins Members
    {
      path: '/admin/members',
      name: 'AdminMembers',
      component: () => import('@views/2_Admin/members.vue'),
      meta: {
        permission: permissionsList.ADMIN_USERS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Mitglieder",
            link: "/admin/members"
          },
        ]
      }
    },
    {
      path: '/admin/members/:id',
      name: 'AdminMemberDetails',
      component: () => import('@views/2_Admin/members/view.vue'),
      meta: {
        permission: permissionsList.ADMIN_USERS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Mitglieder",
            link: "/admin/members"
          },
          {
            name: "Mitglied",
            link: "/admin/members/:id"
          },
        ]
      }
    },
    {
      path: '/admin/members/:id/edit',
      name: 'AdminMemberEdit',
      component: () => import('@views/2_Admin/members/edit.vue'),
      meta: {
        permission: permissionsList.ADMIN_USERS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Mitglieder",
            link: "/admin/members"
          },
          {
            name: "Mitglied Bearbeiten",
            link: "/admin/members/:id/edit"
          }
        ]
      }
    },
    {
      path: '/admin/members/add',
      name: 'AdminMemberAdd',
      component: () => import('@views/2_Admin/members/add.vue'),
      meta: {
        permission: permissionsList.ADMIN_USERS,

        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Mitglieder",
            link: "/admin/members"
          },
          {
            name: "Mitglied Hinzufügen",
            link: "/admin/members/add"
          }
        ]
      }
    },
    
    // Admins Groups
    {
      path: '/admin/groups',
      name: 'AdminGroups',
      component: () => import('@views/2_Admin/groups.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Gruppen",
            link: "/admin/groups"
          },
        ]
      }
    },
    {
      path: '/admin/groups/:id',
      name: 'AdminGroupDetails',
      component: () => import('@views/2_Admin/groups/view.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Gruppen",
            link: "/admin/groups"
          },
          {
            name: "Gruppe",
            link: "/admin/groups/:id"
          },
        ]
      }
    },
    {
      path: '/admin/groups/:id/edit',
      name: 'AdminGroupEdit',
      component: () => import('@views/2_Admin/groups/edit.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Gruppen",
            link: "/admin/groups"
          },
          {
            name: "Gruppe Bearbeiten",
            link: "/admin/groups/:id/edit"
          }
        ]
      }
    },
    {
      path: '/admin/groups/add',
      name: 'AdminGroupAdd',
      component: () => import('@views/2_Admin/groups/add.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Gruppen",
            link: "/admin/groups"
          },
          {
            name: "Gruppe Hinzufügen",
            link: "/admin/groups/add"
          }
        ]
      }
    },

    // Admins Units
    {
      path: '/admin/units',
      name: 'AdminUnits',
      component: () => import('@views/2_Admin/units.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Units",
            link: "/admin/units"
          },
        ]
      }
    },
    {
      path: '/admin/units/:id/edit',
      name: 'AdminUnitEdit',
      component: () => import('@views/2_Admin/units/edit.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Units",
            link: "/admin/units"
          },
          {
            name: "Unit Bearbeiten",
            link: "/admin/units/:id/edit"
          }
        ]
      }
    },
    {
      path: '/admin/units/add',
      name: 'AdminUnitAdd',
      component: () => import('@views/2_Admin/units/add.vue'),
      meta: {
        permission: permissionsList.ADMIN_GROUPS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Units",
            link: "/admin/units"
          },
          {
            name: "Unit Hinzufügen",
            link: "/admin/units/add"
          }
        ]
      }
    },

    // Admin Logs
    {
      path: '/admin/logs',
      name: 'AdminLogs',
      component: () => import('@views/2_Admin/logs.vue'),
      meta: {
        permission: permissionsList.ADMIN_LOGS,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/panel"
          },
          {
            name: "Admin Logs",
            link: "/admin/logs"
          },
        ]
      }
    },

    // Justizministerium
    // {
    //   path: '/justice/folder',
    //   name: 'JusticeFolder',
    //   component: () => import('@views/justice/folder.vue'),
    //   meta: {
    //     permission: permissionsList.ADMIN_ALL,
    //     breadcrumb: [
    //       {
    //         name: "Dashboard",
    //         link: "/panel"
    //       },
    //       {
    //         name: "Justizministerium",
    //         link: "/justice/folder"
    //       },
    //     ]
    //   }
    // },

    // {
    //   path: '/justice/folder/:id',
    //   name: 'JusticeFolderDetails',
    //   component: () => import('@views/justice/folder/view.vue'),
    //   meta: {
    //     permission: permissionsList.ADMIN_ALL,
    //     breadcrumb: [
    //       {
    //         name: "Dashboard",
    //         link: "/panel"
    //       },
    //       {
    //         name: "Justizministerium",
    //         link: "/justice/folder"
    //       },
    //       {
    //         name: "Ordner",
    //         link: "/justice/folder/:id"
    //       },
    //     ]
    //   }
    // },

    // {
    //   path: '/justice/file/templates',
    //   name: 'JusticeFilesTemplates',
    //   component: () => import('@views/justice/file/templates.vue'),
    //   meta: {
    //     breadcrumb: [
    //       {
    //         name: "Dashboard",
    //         link: "/panel"
    //       },
    //       {
    //         name: "Justizministerium",
    //         link: "/justice/files/templates"
    //       },
    //     ]
    //   }
    // },
    // {
    //   path: '/justice/file/:folderId/add',
    //   name: 'JusticeFileAdd',
    //   component: () => import('@views/justice/file/add.vue'),
    //   meta: {
    //     permission: permissionsList.ADMIN_ALL,
    //     breadcrumb: [
    //       {
    //         name: "Dashboard",
    //         link: "/panel"
    //       },
    //       {
    //         name: "Justizministerium",
    //         link: "/justice/folder"
    //       },
    //       {
    //         name: "Ordner",
    //         link: "/justice/folder/:folderId"
    //       },
    //       {
    //         name: "Datei Hinzufügen",
    //         link: "/justice/file/:folderId/add"
    //       },
    //     ]
    //   }
    // },
    // {
    //   path: '/justice/file/:folderId/:fileId',
    //   name: 'JusticeFileDetails',
    //   component: () => import('@views/justice/file/view.vue'),
    //   meta: {
    //     permission: permissionsList.ADMIN_ALL,
    //     breadcrumb: [
    //       {
    //         name: "Dashboard",
    //         link: "/panel"
    //       },
    //       {
    //         name: "Justizministerium",
    //         link: "/justice/folder"
    //       },
    //       {
    //         name: "Ordner",
    //         link: "/justice/folder/:folderId"
    //       },
    //       {
    //         name: "Datei",
    //         link: "/justice/file/:folderId/:fileId"
    //       },
    //     ]
    //   }
    // },
    // {
    //   path: '/justice/file/:folderId/:fileId/edit',
    //   name: 'JusticeFileEdit',
    //   component: () => import('@views/justice/file/edit.vue'),
    //   meta: {
    //     permission: permissionsList.ADMIN_ALL,
    //     breadcrumb: [
    //       {
    //         name: "Dashboard",
    //         link: "/panel"
    //       },
    //       {
    //         name: "Justizministerium",
    //         link: "/justice/folder"
    //       },
    //       {
    //         name: "Ordner",
    //         link: "/justice/folder/:folderId"
    //       },
    //       {
    //         name: "Datei Bearbeiten",
    //         link: "/justice/file/:folderId/:fileId/edit"
    //       },
    //     ]
    //   }
    // },

    // Test Site
    {
      path: '/test',
      name: 'Test',
      component: () => import('@views/Test.vue'),
    },

    // No Permission
    {
      path: '/no-permission',
      name: 'NoPermission',
      component: () => import('@views/NoPermission.vue'),
    },

    // IsSupended
    {
      path: '/banned',
      name: 'IsSuspended',
      component: () => import('@views/IsSuspended.vue'),
    },
    
    // 404
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@views/NotFound.vue'),
    },
  ]
})

export default router
