import { defineStore } from 'pinia'

const useUtilityDataStore = defineStore('utilityData', {
    state: () => {
        return {
            isShiftPressed: false,
        }
    },

    actions: {
        updateShiftPressed(pressed: boolean) {
            this.isShiftPressed = pressed;
        }
    },
});

export default useUtilityDataStore;