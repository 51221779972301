import { createApp } from 'vue';

// Plugins
import { createPinia } from 'pinia'
import router from '@plugin/router'
import pocketbase from '@plugin/pocketbase';
import Notifications from '@kyvg/vue3-notification';
import CKEditor from '@ckeditor/ckeditor5-vue';

// Components
import AppBase from '@/App.vue';
import LoadingScreen from '@components/LoadingScreen.vue';
import ModalYesNo from '@components/ModalYesNo.vue';

// Tippy
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling 

// Styles
import '@assets/tailwind.css';

// Sentry 
import * as Sentry from "@sentry/vue";

class Base {
    static _app: ReturnType<typeof createApp>

    static init() {
        Base._app = createApp(AppBase)
        Base._app.use(router)
        Base._app.use(createPinia())	
        Base._app.use(Notifications)
        Base._app.use(CKEditor)
        Base._app.use(VueTippy)

        // Provide global components
        Base._app.component('LoadingScreen', LoadingScreen)
        Base._app.component('ModalYesNo', ModalYesNo)
        // Provide global variables
        Base._app.config.globalProperties.$pocketbase = pocketbase   
        Base._app.config.globalProperties.$sentry = Sentry

        const app = Base._app
        const env = process.env.NODE_ENV

        if (env === 'production') {
          Sentry.init({
              app,
              dsn: "https://6ba14ece231263d2f51467a1e0030bd1@o4507538549506048.ingest.de.sentry.io/4507538551341136",
              environment: env,
              release: "dienstblatt@1.0.0",
              integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.browserProfilingIntegration(),
                Sentry.replayIntegration(),

                Sentry.feedbackIntegration({
                  colorScheme: "system",
                  isNameRequired: true,
                  isEmailRequired: true,
                  showBranding: false,

                  triggerLabel: "Bug & Feedback",
                  formTitle: "Bug & Feedback Melden",
                  submitButtonLabel: "Absenden",
                  cancelButtonLabel: "Abbrechen",
                  confirmButtonLabel: "Bestätigen",
                  addScreenshotButtonLabel: "Screenshot hinzufügen",
                  removeScreenshotButtonLabel: "Screenshot entfernen",
                  messagePlaceholder: "Beschreibe dein Problem oder Feedback...",
                  successMessageText: "Vielen Dank für dein Feedback oder deinen Bug-Report! Wir werden uns so schnell wie möglich darum kümmern.",
                  nameLabel: "Name",
                  namePlaceholder: "Dein Name",
                  emailLabel: "E-Mail",
                  emailPlaceholder: "Deine E-Mail-Adresse",
                  isRequiredLabel: "(*)",
                  messageLabel: "Nachricht",
                }),
              ],
              trackComponents: true,
              hooks: ["mount", "update", "unmount"],
              Vue: app,

              // Set tracesSampleRate to 1.0 to capture 100%
              // of transactions for tracing.
              // We recommend adjusting this value in production
              tracesSampleRate: 1.0,
            
              // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
              tracePropagationTargets: [
                  "dienstblatt.net",
                  "majestic.dienstblatt.net",
                  "pocketbase.dienstblatt.net"
              ],
            
              // Capture Replay for 10% of all sessions,
              // plus for 100% of sessions with an error
              replaysSessionSampleRate: 0.1,
              replaysOnErrorSampleRate: 1.0,
          });
        }
        Base._app.mount("#app")
    }
}

Base.init()

export default Base
