<template>
    <div v-if="!isLoaded" class="flex items-center justify-center h-full w-full">
        <div class="flex flex-col items-center justify-center">
            <svg aria-hidden="true" class="w-16 h-16 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
            <p class="mt-4">{{ text }}</p>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "LoadingScreen",
    props: {
        isLoaded: {
            type: Boolean,
            required: true
        },

        text: {
            type: String,
            default: "Lade..."
        }
    }
})

</script>
