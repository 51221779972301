<template>
    <transition name="fade" mode="out-in" appear appear-active-class="fade-enter-active">
        <dialog v-if="show" class="modal modal-open" style="z-index: 9999999">
            <div class="modal-box">
                <form method="dialog">
                    <button @click="$emit('ButtonNoAction')" class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        <i :class="icon"></i>
                    </button>
                </form>
                <div class="text-center">
                    <i class="fas fa-exclamation-triangle text-5xl text-error pb-4"></i>
                    <h3 class="mb-5 text-lg font-normal">{{ title }}</h3>
                            
                    <div class="flex gap-4 items-center justify-center">
                        <button @click="$emit('ButtonYesAction')" type="button" class="btn btn-outline btn-sm w-48 btn-success rounded-sm">
                            {{ buttonYesText }}
                        </button>
                        <button @click="$emit('ButtonNoAction')" type="button" class="btn btn-outline btn-sm w-48 btn-error rounded-sm">
                            {{ buttonNoText }}
                        </button>
                    </div>
                </div>
            </div>
        </dialog>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "ModalYesNo",
    emits: ['ButtonYesAction', 'ButtonNoAction'],
    props: {
        show: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        buttonYesText: {
            type: String,
            default: "Ja, löschen"
        },
        buttonNoText: {
            type: String,
            default: "Nein, abbrechen"
        },
        icon: {
            type: String,
            default: 'fas fa-xmark'
        },
    },
})
</script>

<style lang="scss" scoped>
.expand-fade-enter-active, .expand-fade-leave-active {
  transition: all 0.1s ease;
}

.expand-fade-enter-from, .expand-fade-leave-to {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
}
</style>
