<template>
    <header :class="getClassNames">
        <div class="flex flex-row justify-between items-center h-full w-full">
            <div class="flex items-center w-full h-full">
                <button class="focus:outline-none hover:bg-base-content/20 px-1.5 py-0.5 rounded-sm" @click="toggleAside">
                    <i class="fa-regular fa-bars"></i>
                </button>
            </div>
            <div class="flex items-center justify-end w-full h-full gap-2">
                <label class="swap swap-rotate">
                    <input type="checkbox" v-model="themeState" @click="setTheme" />

                    <!-- sun icon -->
                    <i class="swap-off h-4 w-4 fill-current fa-regular fa-sun"></i>
                    <!-- moon icon -->
                    <i class="swap-on h-4 w-4 fill-current fa-regular fa-moon"></i>
                </label>

                <!-- Profile Avatar + Image -->
                <div @click="openProfileDropdown" class="flex items-center justify-center hover:bg-base-content/20 py-1.5 px-1.5 rounded-sm cursor-pointer">
                    <img :src="getAvatarUrl(userData as UserModel)" class="w-7 h-7 rounded-full" alt="Profile Avatar" />
                    <p class="ml-2">{{ userData?.firstname + " " + userData?.lastname }}</p>
                </div>

                <!-- Profile Dropdown -->
                <div v-if="isProfileDropdownOpen" @mouseenter="isMouseOverDropdown = true" @mouseleave="isMouseOverDropdown = false" style="z-index: 9999;">
                    <div class="absolute w-[18rem] right-4 top-16 bg-base-100 border border-base-200 shadow-md rounded-sm" style="z-index: 9999;">
                        <div class="flex items-center px-3 py-2">
                            <img :src="getAvatarUrl(userData as UserModel)" class="w-10 h-10 rounded-full" alt="Profile Avatar" />
                            <div class="flex flex-col ml-3">
                                <p class="text-sm font-bold">{{ userData?.firstname + " " + userData?.lastname }}</p>
                                <p class="text-xs ">{{ userData?.email }}</p>
                            </div>

                            <button class="ml-auto focus:outline-none px-2.5 py-1 rounded-sm hover:bg-base-content/20" @click="closeProfileDropdownButton">
                                <i class="fa-regular fa-xmark"></i>
                            </button>
                        </div>

                        <hr class="border-t border-base-200" />
                        
                        <ul class="flex flex-col space-y-1" style="z-index: 9999;">
                            <li class="hover:bg-base-content/20 p-2" @click="openSite('/settings')">
                                <span class="pl-[1rem]"><i class="fa-regular fa-user"></i> <span class="pl-2">Profil</span></span>
                            </li>
                            
                            <li class="hover:bg-base-content/20 p-2" @click="openSite('/settings')">
                                <span class="pl-[1rem]"><i class="fa-regular fa-cog"></i> <span class="pl-2">Einstellungen</span></span>
                            </li>
                        </ul>

                        <hr class="border-t border-base-200" />

                        <ul class="flex flex-col space-y-1" style="z-index: 9999;">
                            <li class="text-error hover:bg-base-content/20 p-2" @click="openSite('/logout')">
                                <span class="pl-[1rem]"><i class="fa-regular fa-sign-out"></i> <span class="pl-2">Ausloggen</span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import useGlobalDataStore from '@/stores/globalData';
import { mapActions, mapState } from 'pinia';
import useUserDataStore from '@/stores/userData';
import type { UserModel } from '@/models/user';

export default defineComponent({
    name: "Header",
    data() {
        return {
            isProfileDropdownOpen: false,
            isMouseOverDropdown: false,
            currentTheme: "",
            themeState: true
        }
    },
    computed: {
        ...mapState( useGlobalDataStore, ['asideToggled']),
        ...mapState( useUserDataStore, ['userData']),

        getClassNames() {
            const asideToggled = this.asideToggled ? "pl-[1rem]" : "pl-[16rem]";
            return `bg-base-100 border-b border-base-200 shadow-md pr-2 h-14 w-full ${asideToggled}`
        },
    },
    methods: {
        ...mapActions(useGlobalDataStore, ['toggleAside']),

        getAvatarUrl(user: UserModel) {
            if (user) {
                if (user.avatarImage && user.avatarImage !== "") {
                    const url = this.$pocketbase.getDb().getFileUrl(user, user.avatarImage as string);
                    return url;
                } else {
                    return `https://api.dicebear.com/8.x/initials/svg?seed=${user.username}`;
                }
            } else {
                return `https://api.dicebear.com/8.x/initials/svg?seed=TEST_TEST`;
            }
        },

        openSite(name: string) {
            this.$router.push({ path: name });
        },

        openProfileDropdown() {
            if (this.isProfileDropdownOpen) {
                this.isProfileDropdownOpen = false;
                this.isMouseOverDropdown = false;

                if (document.body) {
                    document.body.removeEventListener('click', this.closeProfileDropdown);
                }
                return;   
            }

            this.isProfileDropdownOpen = true;

            setTimeout(() => {
                if (document.body) {
                    document.body.addEventListener('click', this.closeProfileDropdown);
                }
            }, 100);
        },

        closeProfileDropdownButton() {
            this.isProfileDropdownOpen = false;
            this.isMouseOverDropdown = false;

            if (document.body) {
                document.body.removeEventListener('click', this.closeProfileDropdown);
            }
        },

        closeProfileDropdown() {
            if (this.isMouseOverDropdown) return;

            this.isProfileDropdownOpen = false;
            this.isMouseOverDropdown = false;

            if (document.body) {
                document.body.removeEventListener('click', this.closeProfileDropdown);
            }
        },

        setTheme() {
            const theme = this.themeState ? 'dark' : 'light';
            this.currentTheme = theme;
            localStorage.setItem('theme', theme);
            document.documentElement.setAttribute('data-theme', theme);
        }
    },
    mounted() {
        const theme = localStorage.getItem('theme');
        switch (theme) {
            case 'dark':
                localStorage.setItem('theme', 'dark');
                document.documentElement.setAttribute('data-theme', 'dark');
                this.currentTheme = 'dark';
                this.themeState = false;
                break;
            case 'light':
                localStorage.setItem('theme', 'light');
                document.documentElement.setAttribute('data-theme', 'light');
                this.currentTheme = 'light';
                this.themeState = true;
                break;
            default:
                const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
                localStorage.setItem('theme', systemTheme);
                document.documentElement.setAttribute('data-theme', systemTheme);
                this.currentTheme = systemTheme;
                this.themeState = systemTheme === 'dark' ? false : true;
                break;
        }
    }
})
</script>
