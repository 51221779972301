import type { UserModel } from '@/models/user';
import { defineStore } from 'pinia'

const useUserDataStore = defineStore('userData', {
    state: () => {
        return {
            userData: null as UserModel | null | undefined,
        }
    },

    actions: {
        updateData(data: UserModel | null | undefined) {
            this.userData = data;
        }
    },
});

export default useUserDataStore;