<template>
    <notifications group="notify" width="25rem" style="z-index: 999999999"/>

    <template
            v-if="isLogin || isLoginCallback || isSettingsCallback || isForgotPassword || isLogout || is404 || isSuspended">
        <RouterView/>
        <div class="absolute top-6 right-6 h-8 w-8 flex items-center justify-center" style="z-index: 9999;">
            <label class="swap swap-rotate">
                <input type="checkbox" v-model="themeState" @click="setTheme"/>

                <!-- sun icon -->
                <i class="swap-off h-4 w-4 fill-current fa-regular fa-sun text-white"></i>
                <!-- moon icon -->
                <i class="swap-on h-4 w-4 fill-current fa-regular fa-moon text-white"></i>
            </label>
        </div>

        <div class="absolute left-1/2 -translate-x-1/2 top-40 flex flex-col items-center justify-center textcolor-light">
            <h2 class="text-2xl font-bold uppercase">Release in</h2>
            <div class="grid auto-cols-max grid-flow-col gap-5 text-center">
                <div class="flex flex-col">
          <span class="countdown font-mono text-5xl">
            <span :style="'--value:' + countdown.days + ';'"></span>
          </span>
                    days
                </div>
                <div class="flex flex-col">
          <span class="countdown font-mono text-5xl">
            <span :style="'--value:' + countdown.hours + ';'"></span>
          </span>
                    hours
                </div>
                <div class="flex flex-col">
          <span class="countdown font-mono text-5xl">
            <span :style="'--value:' + countdown.minutes + ';'"></span>
          </span>
                    min
                </div>
                <div class="flex flex-col">
          <span class="countdown font-mono text-5xl">
            <span :style="'--value:' + countdown.seconds + ';'"></span>
          </span>
                    sec
                </div>
            </div>
        </div>
    </template>

    <template v-else-if="renderComponent">
        <div className="flex flex-row min-h-screen content-wrapper">
            <AsideComponent/>
            <main :class="'main flex flex-col flex-grow transition-all duration-150 ease-in ' + getPaddingClasses">
                <HeaderComponent/>
                <div :class="getClassNames">
                    <Breadcrubs/>
                    <RouterView/>
                </div>

                <FooterComponent :class="asideToggled ? 'pl-[4rem]' : 'pl-0 md:pl-[15.5rem]'"/>
            </main>
        </div>
    </template>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {RouterView} from 'vue-router';

// Layout Components
import AsideComponent from '@views/layout/Aside.vue'
import FooterComponent from '@views/layout/Footer.vue'
import HeaderComponent from '@views/layout/Header.vue'
import Breadcrubs from '@views/layout/Breadcrubs.vue'

// State
import useUserData from '@/stores/userData';
import type {UserModel} from './models/user';
import {mapActions, mapState} from 'pinia';
import useGlobalData from '@/stores/globalData';
import useUtilityDataStore from "@stores/utilityData.ts";

export default defineComponent({
    name: 'App',
    components: {
        RouterView,
        AsideComponent,
        FooterComponent,
        HeaderComponent,
        Breadcrubs
    },

    data() {
        return {
            renderComponent: false,
            isNeedData: false,
            isAuthRoute: false,
            currentTheme: "",
            themeState: true,

            // Countdown
            countdown: {
                interval: null as any,
                targedDate: new Date('2024-08-09 19:00:00').getTime(),
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            }
        }
    },

    methods: {
        ...mapActions(useUtilityDataStore, ['updateShiftPressed']),

        setTheme() {
            const theme = this.themeState ? 'dark' : 'light';
            this.currentTheme = theme;
            localStorage.setItem('theme', theme);
            document.documentElement.setAttribute('data-theme', theme);
        }
    },

    computed: {
        ...mapState(useGlobalData, ['asideToggled']),
        ...mapState(useUtilityDataStore, ['isShiftPressed']),

        getPaddingClasses() {
            if (!this.asideToggled) {
                return `pl-[16rem] md:pl-[0rem]`;
            }
        },

        getClassNames() {
            const asideToggled = this.asideToggled ? "" : "pl-0 md:pl-[15.5rem]";
            return `main-content flex flex-col flex-grow px-2 py-4 ${asideToggled}`
        },

        isLogin() {
            return this.$route.name === 'Login';
        },

        isLoginCallback() {
            return this.$route.name === 'LoginCallback';
        },

        isSettingsCallback() {
            return this.$route.name === 'SettingsCallback';
        },

        isForgotPassword() {
            return this.$route.name === 'ForgotPassword';
        },

        isLogout() {
            return this.$route.name === 'Logout';
        },

        isSuspended() {
            return this.$route.name === 'IsSuspended';
        },

        is404() {
            return this.$route.name === 'NotFound';
        },
    },

    async mounted() {
        // Fix for sentry feedback button
        const feedbackButton = document.getElementById("sentry-feedback")?.shadowRoot?.querySelector("button");
        if (feedbackButton) {
            feedbackButton.style.setProperty("inset", "auto auto 0 0");
            feedbackButton.style.setProperty("border-radius", "10px");
            feedbackButton.style.setProperty("padding", "0.75rem 2.4rem");
        }

        // Wait if route is ready
        await this.$router.isReady();

        // Check if Shift is pressed
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') {
                this.updateShiftPressed(true);
            }
        });

        window.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') {
                this.updateShiftPressed(false);
            }
        });

        const theme = localStorage.getItem('theme');
        switch (theme) {
            case 'dark':
                localStorage.setItem('theme', 'dark');
                document.documentElement.setAttribute('data-theme', 'dark');
                this.currentTheme = 'dark';
                this.themeState = false;
                break;
            case 'light':
                localStorage.setItem('theme', 'light');
                document.documentElement.setAttribute('data-theme', 'light');
                this.currentTheme = 'light';
                this.themeState = true;
                break;
            default:
                const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
                localStorage.setItem('theme', systemTheme);
                document.documentElement.setAttribute('data-theme', systemTheme);
                this.currentTheme = systemTheme;
                this.themeState = systemTheme === 'dark' ? false : true;
                break;
        }

        this.countdown.interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = this.countdown.targedDate - now;

            this.countdown.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.countdown.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.countdown.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.countdown.seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(this.countdown.interval);
                this.countdown.days = 0;
                this.countdown.hours = 0;
                this.countdown.minutes = 0;
                this.countdown.seconds = 0;
            }
        }, 1000);


        // Check if a auth route is called
        if (this.$route.name === 'Login' || this.$route.name === 'LoginCallback' || this.$route.name === "SettingsCallback" || this.$route.name === 'ForgotPassword' || this.$route.name === 'Logout' || this.$route.name === 'NotFound' || this.$route.name === 'IsSuspended') {
            this.renderComponent = false;
            return;
        }

        const data = await this.$pocketbase.getCurrentUser();
        if (data) {
            if (data.firstname === "" || data.lastname === "") {
                this.isNeedData = true;
            }

            this.$sentry.setUser({
                email: data.email,
                id: data.id,
                username: data.username
            })

            const userData = useUserData();
            userData.updateData(data as UserModel);

            if (data.isSuspendiert) {
                this.$router.push({name: 'IsSuspended'});
                return;
            }

            await this.$pocketbase.getCollection("users").subscribe(data.id, (r) => {
                userData.updateData(r.record as UserModel);
                this.$pocketbase.getDb().authStore.save(this.$pocketbase.getDb().authStore.token, r.record)
            }).catch((e) => {
                console.error(e);
            });

            this.renderComponent = true;

        } else {
            this.$router.push({name: 'Login'});
        }
    },

    async unmounted() {
        const data = await this.$pocketbase.getCurrentUser();
        if (data) {
            await this.$pocketbase.getCollection("users").unsubscribe(data.id);
        }
    },
})
</script>

<style lang="scss">
.vue-notification-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0 0 0 0 !important;
  padding: 0.25vw 0 0 0 !important;
}

body {
  background-color: black;
}
</style>