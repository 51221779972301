<template>
    <footer class="footer pr-4 py-6 justify-center">
        <div class="footer-content">
            <p class="text-sm text-center">© LeCyreaxYT {{ year }}. All rights reserved. <a href="#" class="font-bold">by LeCyreaxYT</a></p>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "Footer",
    data() {
        return {
            year: new Date().getFullYear()
        }
    },
})
</script>