import PocketBase, { AsyncAuthStore } from 'pocketbase';
import { Preferences } from '@capacitor/preferences';
import router from '@plugin/router';
import type { eLogAction } from '@utils/logactions';
import type { UserModel } from '@/models/user';

const authKey = 'pb_auth';

async function getInitial(): Promise<any> {
    const serialized = (await Preferences.get({ key: authKey })).value;
	if (serialized) {
		return serialized
	}
}

const preferencesAsyncAuthStore = new AsyncAuthStore({
	save: async (serialized) => await Preferences.set({ key: authKey, value: serialized }),
	clear: async () => await Preferences.remove({ key: authKey }),
    initial: getInitial(),
});

class Database {
    // Only one instance of the database is created
    private static instance: Database;
    private db: PocketBase;

    private currentUser: UserModel | null | undefined = null;

    public url = '';
    public redirectUrl = '';
    public settingsRedirectUrl = '';

    private constructor() {
        if (window.location.hostname === 'localhost') {
            this.url = import.meta.env.VITE_POCKETBASE_URL;
            this.redirectUrl = import.meta.env.VITE_POCKETBASE_REDIRECT_URL_TEST;
            this.settingsRedirectUrl = import.meta.env.VITE_POCKETBASE_REDIRECT_URL_SETTINGS_TEST;
        } else {
            this.url = import.meta.env.VITE_POCKETBASE_URL;
            this.redirectUrl = import.meta.env.VITE_POCKETBASE_REDIRECT_URL_PROD;
            this.settingsRedirectUrl = import.meta.env.VITE_POCKETBASE_REDIRECT_URL_SETTINGS_PROD;
        }

        this.db = new PocketBase(this.url, preferencesAsyncAuthStore);
        this.db.autoCancellation(false);
    }

    public static getInstance(): Database {
        if (!Database.instance) {
            Database.instance = new Database();
        }

        return Database.instance;
    }

    public getDb(): PocketBase {
        return this.db;
    }

    public getCollection(collectionName: string) {
        return this.db.collection(collectionName);
    }

    public async logAction(userId: string, type: eLogAction, message: string) {
        await this.db.collection("logs").create({
            user: userId,
            type: type,
            message: message,
        });
    }

    private async tryGetUser(): Promise<{ model: UserModel | null | undefined, token: string, isValid: boolean }> {
        const authStore = this.db.authStore;

        try {
            if (authStore && authStore.isValid) {    
                return {
                    model: authStore.model as UserModel | null | undefined,
                    token: authStore.token,
                    isValid: authStore.isValid,
                };
            } else {
                return {
                    model: null,
                    token: '',
                    isValid: false,
                };
            }
        } catch (error) {
            return {
                model: null,
                token: '',
                isValid: false,
            };
        }
    }

    public async getCurrentUser(): Promise<UserModel | null | undefined> {  
        try {
            this.db.authStore.isValid && await this.db.collection('users').authRefresh({
                expand: "unit,rank"
            });
        } catch (e) {
            this.db.authStore.clear(); // after this call pb.authStore.isValid will be false
        }

        if (this.currentUser) {
            await this.db.collection("users").authRefresh({
                expand: "unit,rank",
            });
            return this.currentUser;
        } else {
            return new Promise(async (resolve, reject) => {
                const user = await this.tryGetUser();

                // Try 10 times to get the user then reject
                if (user) {
                    resolve(user.model);
                }

                let tries = 0;
                const interval = setInterval(async () => {
                    if (tries > 100) {
                        clearInterval(interval);
                        this.db.authStore.clear();
                        router.push({ path: '/login' });
                        reject(); 
                    }

                    const user = await this.tryGetUser();

                    if (user) {
                        clearInterval(interval);
                        this.currentUser = user.model;
                        await this.db.collection("users").authRefresh({
                            expand: "unit,rank",
                        });
                        resolve(user.model);
                    }

                    tries++;
                }, 10);
            });
        }    
    }
}

export default Database.getInstance();