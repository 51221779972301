<template>
    <!-- Breadcrubs -->
    <nav class="flex h-7 px-4" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-2">
            <template v-for="(breadcrumb, index) in breadcrumbs">
                <li class="inline-flex items-center" v-if="breadcrumb.name && index === 0" :key="index">
                    <a class="inline-flex items-center text-sm font-medium hover:underline" :href="breadcrumb.link">
                        <i class="fa-solid fa-home w-4 h-4 mr-2"></i>
                        {{ breadcrumb.name }}
                    </a>
                </li>

                <li v-else>
                    <div class="flex items-center">
                        <i class="fa-light fa-chevron-right text-sm w-2 h-2 text-base-content/50"></i>
                        <a :href="breadcrumb.link" class="ml-1 text-sm font-medium md:ml-2 hover:underline">{{ breadcrumb.name }}</a>
                    </div>
                </li>
            </template>
        </ol>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "Breadcrubs",
    data() {
        return {
            breadcrumbs: [] as { name: string, link: string }[]
        }
    },
    watch: {
        $route() {
            this.$route.matched.map((route: any) => {
                const breadcrumbs = route.meta.breadcrumb;
                this.breadcrumbs = breadcrumbs;
            })
        }
    },
    mounted() {
        this.$route.matched.map((route: any) => {
            let breadcrumbs = route.meta.breadcrumb;

            // Replace :id with actual id
            if (breadcrumbs) {
                for (let breadcrumb of breadcrumbs) {
                    if (breadcrumb.link.includes(":id")) {
                        const id = this.$route.params.id;
                        breadcrumb.link = breadcrumb.link.replace(":id", id);
                    }

                    if (breadcrumb.link.includes(":folderId")) {
                        const id2 = this.$route.params.folderId;
                        breadcrumb.link = breadcrumb.link.replace(":folderId", id2);
                    }

                    if (breadcrumb.link.includes(":fileId")) {
                        const id3 = this.$route.params.fileId;
                        breadcrumb.link = breadcrumb.link.replace(":fileId", id3);
                    }
                }
            }


            this.breadcrumbs = breadcrumbs;

        })
    }
})
</script>
