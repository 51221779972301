<template>
    <aside :class="getAsideClass" style="z-index: 99999;" aria-label="Sidenav">
        <div class="h-14 hidden top-0 left-0 justify-center px-4 py-1.5 space-x-4 w-full lg:flex bg-base-100 z-20 border-r border-base-200">
            <div class="flex flex-row justify-center items-center content-center hover:cursor-pointer" @click="openRoute('Dashboard')">
                <img :src="siteLogo" loading="lazy" alt="logo" class="w-8 h-8 rounded-full">
                <p class="ml-3  font-semibold text-1xl">{{ siteTitle }}</p>
            </div>
        </div>
        
        <div class="overflow-y-auto no-scrollbar pt-1.5 pb-6 h-full border-t-0 border-b-0 border-l-0 border-r bg-base-100 border-base-200">
            <ul class="menu mb-6">
                <li class="mb-0.5">
                    <a @click="openRoute('Dashboard')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-lg hover:bg-base-content/20 group">
                        <i class="fa-regular fa-house"></i><span class="ml-2">Dashboard</span>
                    </a>
                </li>

                <li class="mb-0.5">
                    <a @click="openRoute('Patrols')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-lg hover:bg-base-content/20 group">
                        <i class="fa-regular fa-car"></i><span class="ml-2">Leitstelle</span>
                    </a>
                </li>

                <li class="mb-0.5">
                    <a @click="openRoute('Members')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-lg hover:bg-base-content/20 group">
                        <i class="fa-regular fa-user"></i><span class="ml-2">Mitarbeiter</span>
                    </a>
                </li>
                
                <template v-if="adminMembers || adminRoles">
                    <li class="menu-title font-semibold">Administration</li>

                    <li class="mb-0.5" v-if="adminGlobalSettings">
                        <a @click="openRoute('AdminGlobalSettings')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-sm hover:bg-base-content/20 group">
                            <i class="fa-regular fa-gear-complex"></i><span class="ml-2">Globale Einstellungen</span>
                        </a>
                    </li>

                    <li class="mb-0.5" v-if="adminMembers">
                        <a @click="openRoute('AdminMembers')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-sm hover:bg-base-content/20 group">
                            <i class="fa-regular fa-users"></i><span class="ml-[0.15rem]">Mitglieder</span>
                        </a>
                    </li>

                    <li class="mb-0.5" v-if="adminRoles">
                        <a @click="openRoute('AdminGroups')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-sm hover:bg-base-content/20 group">
                            <i class="fa-regular fa-user"></i><span class="ml-2">Rollen</span>
                        </a>
                    </li>

                    <li class="mb-0.5" v-if="adminUnits">
                        <a @click="openRoute('AdminUnits')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-sm hover:bg-base-content/20 group">
                            <i class="fa-regular fa-user"></i><span class="ml-2">Abteilungen</span>
                        </a>
                    </li>

                    <li class="mb-0.5" v-if="adminLogs">
                        <a @click="openRoute('AdminLogs')" class="flex items-center px-4 py-1.5 text-base font-normal rounded-sm hover:bg-base-content/20 group">
                            <i class="fa-regular fa-floppy-disk"></i><span class="ml-2">Protokolle</span>
                        </a>
                    </li>
                </template>

                <template v-if="units">
                    <li class="menu-title font-semibold">Abteilungen</li>

                    <template v-for="(item, index) in units" :key="index">
                        <li class="mb-0.5" v-if="canSeeUnit(item)">
                            <details>
                                <summary>
                                    <i :class="item.icon"></i><span class="ml-2 overflow-hidden whitespace-nowrap text-ellipsis">{{ item.name }}</span>
                                </summary>
                                <ul>
                                    <li class="mb-0.5">
                                        <a class="flex items-center px-4 py-1.5 text-base font-normal rounded-sm hover:bg-base-content/20 group">
                                            <i class="fa-regular fa-floppy-disk"></i><span class="ml-2">Test</span>
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </li>
                    </template>
                </template>
            </ul>
        </div>

    </aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import userData from '@/stores/userData';
import globalData from '@stores/globalData';

import { mapState } from 'pinia';
import { hasPermission, permissionsList } from '@/utils/permissions';
import type { UnitModel } from '@/models/units';
import type { UserModel } from '@/models/user';

export default defineComponent({
    name: "Aside",
    data() {
        return {
            // Administation
            adminMembers: false,
            adminRoles: false,
            adminUnits: false,
            adminLogs: false,
            adminGlobalSettings: false,

            // Justice
            justiceFiles: true,
            justiceTemplates: true,

            // Units
            units: [] as UnitModel[],
            canSeeAllUnits: false,
        
            // Site Data
            siteTitle: import.meta.env.VITE_SITE_TITLE,
            siteLogo: import.meta.env.VITE_SITE_LOGO,
        }
    },

    computed: {
        ...mapState( userData, ['userData']),
        ...mapState( globalData, ['asideToggled']),

        getAsideClass() {
            const classToggled = this.asideToggled ? 'hidden' : 'w-60';
            return `fixed top-0 left-0 ${classToggled} h-full`;
        },
    },

    methods: {
        openRoute(route: string) {
            this.$router.push({ name: route});
        },

        getAvatarUrl(user: UserModel) {
            if (user) {
                if (user.avatarImage && user.avatarImage !== "") {
                    const url = this.$pocketbase.getDb().getFileUrl(user, user.avatarImage as string);
                    return url;
                } else {
                    return `https://api.dicebear.com/8.x/initials/svg?seed=${user.username}`;
                }
            } else {
                return `https://api.dicebear.com/8.x/initials/svg?seed=TEST_TEST`;
            }
        },

        canSeeUnit(unit: UnitModel) {
            if (this.canSeeAllUnits) {
                return true;
            }

            if (this.userData) {
                if (unit.id === this.userData.unit) {
                    return true;
                }
            }

            return false;
        },

        openJusticeFiles() {
            this.$router.push({ name: 'JusticeFolder'});
        },
        openJusticeTemplates() {
            this.$router.push({ name: 'JusticeTemplates'});
        }
    },
    async mounted() {
        if (this.userData) {
            const rank = await this.$pocketbase.getCollection("groups").getOne(this.userData.rank as string);
            if (rank) {
                if (await hasPermission(this, permissionsList.ADMIN_USERS)) {
                    this.adminMembers = true;
                }

                if (await hasPermission(this, permissionsList.ADMIN_GROUPS)) {
                    this.adminRoles = true;
                }

                if (await hasPermission(this, permissionsList.ADMIN_UNITS)) {
                    this.adminUnits = true;
                }

                if (await hasPermission(this, permissionsList.ADMIN_LOGS)) {
                    this.adminLogs = true;
                }

                if (await hasPermission(this, permissionsList.ADMIN_GLOBAL_SETTINGS)) {
                    this.adminGlobalSettings = true;
                }

                // canSeeAllUnits
                if (await hasPermission(this, permissionsList.SIDEBAR_SEEALLUNITS)) {
                    this.canSeeAllUnits = true;
                }
            }

            const units = await this.$pocketbase.getCollection("units").getFullList();
            if (units) {
                this.units = units as UnitModel[];
                this.units = this.units.sort((a, b) => Number(a.sort) - Number(b.sort));
            }
        }
    },

    unmounted() {
    }
})
</script>
