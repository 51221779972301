import { defineStore } from 'pinia'

const useGlobalDataStore = defineStore('globalData', {
    state: () => {
        return {
            isAsideToggled: false,
        }
    },

    getters: {
        asideToggled: (state) => state.isAsideToggled,
    },

    actions: {
        toggleAside() {
            this.isAsideToggled = !this.asideToggled;
        },

        setAsideToggled(value: boolean) {
            this.isAsideToggled = value;
        }
    },
});

export default useGlobalDataStore;