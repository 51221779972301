enum permissionsList {
    ADMIN_ALL = 'admin.all',
    ADMIN_USERS = 'admin.users',
    ADMIN_GROUPS = 'admin.groups',
    ADMIN_UNITS = 'admin.units',
    ADMIN_LOGS = 'admin.logs',
    ADMIN_GLOBAL_SETTINGS = 'admin.globalsettings',

    SIDEBAR_SEEALLUNITS = 'sidebar.seeallunits',

    // JUSTICE_FOLDER = 'justice.folder',
    // JUSTICE_FOLDER_ADD = 'justice.folder.add',
    // JUSTICE_FOLDER_EDIT = 'justice.folder.edit',
    // JUSTICE_FOLDER_DELETE = 'justice.folder.delete',
    // JUSTICE_FILE = 'justice.file',
    // JUSTICE_FILE_ADD = 'justice.file.add',
    // JUSTICE_FILE_EDIT = 'justice.file.edit',
    // JUSTICE_FILE_DELETE = 'justice.file.delete',

    PATROL_MANAGE = 'patrol.manage',
    PATROL_EDIT = 'patrol.edit',
}

interface Permission {
    admin_all: boolean;
    admin_users: boolean;
    admin_groups: boolean;
    admin_units: boolean;
    admin_logs: boolean;
    admin_global_settings: boolean;

    sidebar_seeallunits: boolean;

    // justice_folder: boolean;
    // justice_folder_add: boolean;
    // justice_folder_edit: boolean;
    // justice_folder_delete: boolean;
    // justice_file: boolean;
    // justice_file_add: boolean;
    // justice_file_edit: boolean;
    // justice_file_delete: boolean;

    patrol_manage: boolean;
    patrol_edit: boolean;
}

function hasPermissionList(permissions: string[], permission: string): boolean {
    const hasAdminAll = permissions.includes(permissionsList.ADMIN_ALL);
    if (hasAdminAll) {
        return true;
    }

    return permissions.includes(permission);
}

function generatePermissionsList(permissions: Permission): string[] {
    let permissionsArray: string[] = [];

    if (permissions.admin_all) {
        permissionsArray.push(permissionsList.ADMIN_ALL);
    }

    if (permissions.admin_users) {
        permissionsArray.push(permissionsList.ADMIN_USERS);
    }

    if (permissions.admin_groups) {
        permissionsArray.push(permissionsList.ADMIN_GROUPS);
    }

    if (permissions.admin_units) {
        permissionsArray.push(permissionsList.ADMIN_UNITS);
    }

    if (permissions.admin_logs) {
        permissionsArray.push(permissionsList.ADMIN_LOGS);
    }

    if (permissions.admin_global_settings) {
        permissionsArray.push(permissionsList.ADMIN_GLOBAL_SETTINGS);
    }

    if (permissions.sidebar_seeallunits) {
        permissionsArray.push(permissionsList.SIDEBAR_SEEALLUNITS);
    }


    // // Justice
    // if (permissions.justice_folder) {
    //     permissionsArray.push(permissionsList.JUSTICE_FOLDER);
    // }

    // if (permissions.justice_folder_add) {
    //     permissionsArray.push(permissionsList.JUSTICE_FOLDER_ADD);
    // }

    // if (permissions.justice_folder_edit) {
    //     permissionsArray.push(permissionsList.JUSTICE_FOLDER_EDIT);
    // }

    // if (permissions.justice_folder_delete) {
    //     permissionsArray.push(permissionsList.JUSTICE_FOLDER_DELETE);
    // }

    // if (permissions.justice_file) {
    //     permissionsArray.push(permissionsList.JUSTICE_FILE);
    // }

    // if (permissions.justice_file_add) {
    //     permissionsArray.push(permissionsList.JUSTICE_FILE_ADD);
    // }

    // if (permissions.justice_file_edit) {
    //     permissionsArray.push(permissionsList.JUSTICE_FILE_EDIT);
    // }

    // if (permissions.justice_file_delete) {
    //     permissionsArray.push(permissionsList.JUSTICE_FILE_DELETE);
    // }

    if (permissions.patrol_manage) {
        permissionsArray.push(permissionsList.PATROL_MANAGE);
    }

    if (permissions.patrol_edit) {
        permissionsArray.push(permissionsList.PATROL_EDIT);
    }

    return permissionsArray;
}

function generateHasPermissionList(permissionsArray: string[]): Permission {
    let permissions: Permission = {
        admin_all: false,
        admin_users: false,
        admin_groups: false,
        admin_units: false,
        admin_logs: false,
        admin_global_settings: false,

        sidebar_seeallunits: false,

        // justice_folder: false,
        // justice_folder_add: false,
        // justice_folder_edit: false,
        // justice_folder_delete: false,
        // justice_file: false,
        // justice_file_add: false,
        // justice_file_edit: false,
        // justice_file_delete: false,

        patrol_manage: false,
        patrol_edit: false,
    };

    if (permissionsArray.includes(permissionsList.ADMIN_ALL)) {
        permissions.admin_all = true;
    }

    if (permissionsArray.includes(permissionsList.ADMIN_USERS)) {
        permissions.admin_users = true;
    }

    if (permissionsArray.includes(permissionsList.ADMIN_GROUPS)) {
        permissions.admin_groups = true;
    }

    if (permissionsArray.includes(permissionsList.ADMIN_UNITS)) {
        permissions.admin_units = true;
    }

    if (permissionsArray.includes(permissionsList.ADMIN_LOGS)) {
        permissions.admin_logs = true;
    }

    if (permissionsArray.includes(permissionsList.ADMIN_GLOBAL_SETTINGS)) {
        permissions.admin_global_settings = true;
    }

    if (permissionsArray.includes(permissionsList.SIDEBAR_SEEALLUNITS)) {
        permissions.sidebar_seeallunits = true;
    }

    // // Justice
    // if (permissionsArray.includes(permissionsList.JUSTICE_FOLDER)) {
    //     permissions.justice_folder = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FOLDER_ADD)) {
    //     permissions.justice_folder_add = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FOLDER_EDIT)) {
    //     permissions.justice_folder_edit = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FOLDER_DELETE)) {
    //     permissions.justice_folder_delete = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FILE)) {
    //     permissions.justice_file = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FILE_ADD)) {
    //     permissions.justice_file_add = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FILE_EDIT)) {
    //     permissions.justice_file_edit = true;
    // }

    // if (permissionsArray.includes(permissionsList.JUSTICE_FILE_DELETE)) {
    //     permissions.justice_file_delete = true;
    // }

    if (permissionsArray.includes(permissionsList.PATROL_MANAGE)) {
        permissions.patrol_manage = true;
    }

    if (permissionsArray.includes(permissionsList.PATROL_EDIT)) {
        permissions.patrol_edit = true;
    }
    
    return permissions;
}

async function hasPermission(_this: any, permission: string): Promise<boolean> {
    const rank = await _this.$pocketbase.getCollection("groups").getOne(_this.userData.rank);
    return hasPermissionList(rank.permissions, permission);
}


export { permissionsList, hasPermission, generatePermissionsList, generateHasPermissionList };
export type { Permission };